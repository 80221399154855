import * as React from "react"
import Layout from "../../components/layout"
import AniLink from "gatsby-plugin-transition-link/AniLink";
import Servicelinks from "../../components/servicelinks"
import Helmet from 'react-helmet'
import useIntersection from '../../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '-90px',
    threshold: 0.4 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};

const SEO = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'service-page'
    }}
/>
  <Layout pageTitle="SEO">
    <section id="main">
      <div id="service-intro">
        <div className="left-col">
          <h1><span className="revealblack">Search Engines Optimisation</span></h1>
          <p><span className="revealblack">Search Engines Optimisation (SEO) is an activity that attempts to improve search engine rankings, for your potential customers to find you on search engines and also convert visitors to sales and customers.</span></p>
          <a className="button sendusemail" href="#sendusemail"><span className="seemore"><i>&#xe801;</i></span>Send us an email</a>
        </div>
        <div className="right-col">
            <img className="picture" src={'/img/services/tobstar-seo-header.jpg'} alt="SEO" />
        </div>
      </div>
    </section>

    <Section id="service-content">
      <div id="inner-content">

<p>We optimise websites for SEO, and make recommendations on your content and keywords, with <AniLink cover to="/capabilities/wordpress-development-melbourne" bg="#00ccd4" direction="right" duration={0.76}>WordPress</AniLink> we include Yoast SEO plugin to assist clients in specifying page titles and descriptions for search engine results in pages and using of your target keywords. We also provide set up of Google Analytics, Google’s Search Console Tool, Bing’s Webmaster Tool, create XML sitemap file and submit to Google and Bing, providing keyword suggestions and your competitors analysis, improving your social media and to get more visitors by increasing more internal links to your website to improve your ranking pages.</p>

<p>We provide SEO report and recommendations with use of SEO spider tool from Screaming Frog to find any SEO issues.</p>

<p>We don’t offer guarantee and you should be wary of any SEO company who “guarantees” page 1 result. There are no guarantees in optimisation and it takes time to work your way to the top based on many different factors such as popularity and traffic you get.</p>

<p>Do your homework and understand what kind of content your potential customers are looking for. We create a great site, add your content that people need and make it easy for them to find and share it.</p>

      </div>
       <Servicelinks></Servicelinks>
   </Section>
</Layout>

  </>
  )
}

export default SEO


export const Head = () => (
  <>
    <title>Search Engines Optimisation - Tobstar</title>
<meta name="description" content="Search Engines Optimisation (SEO) is an activity that attempts to improve search engine rankings, for your potential customers to find you on search engines" />
  </>
)